export default [
  {
    id: "fisioterapia",
    title: "Fisioterapia",
    subtitle: "Reabilitação de todo o tipo de patologia músculo-esquelética",
    copy:
      "Estamos especialmente vocacionados para a reabilitação de patologias músculo-esqueléticas e lesões desportivas. Dispomos de equipamentos e técnicas avançadas para tratamento de patologia muscular, ligamentar, tendinosa e osteo-articular.",
  },
  {
    id: "osteopatia",
    title: "Osteopatia",
    subtitle: "Manipulação articular, fascial e visceral",
    copy:
      "A osteopatia permite melhorar a mobilidade de várias estruturas osteoarticulares, através da manipulação articular, muscular, fascial e visceral. Apresenta grande efectividade no tratamento de patologias como a dor ciática, lombalgia, dorsalgia, cervicalgia, torcicolo. As técnicas utilizadas permitem reduzir o desconforto e promover a autorregulação do organismo.",
  },
  {
    id: "osteopatia-pediatrica",
    title: "Osteopatia Pediátrica",
    subtitle: "Tratamento do recém-nascido e criança",
    copy:
      "A osteopatia pediátrica permite, com base nos princípios da mobilidade das várias estruturas osteoarticulares, tratar vários distúrbios que podem afetar a criança desde o momento do parto. Esta prática permite avaliar a criança sendo útil nas seguintes situações: plagiocefalia (deformações cranianas), torcicolo congénito ou adquirido, cólicas, agitação e nervosismo, transtornos de sono, problemas respiratórios.",
  },
  {
    id: "medicinachinesa",
    title: "Medicina Tradicional Chinesa",
    subtitle: "Acupuntura",
    copy:
      "A acupuntura japonesa é uma excelente técnica para os seguintes tratamentos: ansiedade, depressão, ataques de pânico, insónias, irritabilidade.",
  },
  {
    id: "prp",
    title: "PRP’s",
    subtitle: "Factores de crescimento",
    copy:
      "Consiste na utilização de Plasma autólogo rico em Factores de Crescimento, no tratamento de lesões ortopédicas. Os Factores de Crescimento estimulam o processo de regeneração biológica do músculo, tendão e ligamento. É um processo praticamente indolor e útil para estimular a cicatrização natural do organismo.",
  },
  {
    id: "antiaging",
    title: "Medicina Anti-Aging",
    subtitle: "Fatores de risco indutores do envelhecimento",
    copy:
      "Diagnóstico e monitorização de fatores de risco indutores do envelhecimento. A medicina anti-envelhecimento permite a implementação de um plano personalizado de tratamento para a reposição do equilíbrio bioquímico e neutralização dos efeitos tóxicos. É uma medicina preventiva que permite diminuir o processo degenerativo celular, atuando nas seguintes situações: emagrecimento, revenção de doenças cardiovasculares, redução dos sintomas de menopausa, depressão, alterações de humor, diminuição de líbido, entre outros.",
  },
  {
    id: "podologia",
    title: "Podologia",
    subtitle: "Diagnóstico e tratamento das patologias que afectam o pé",
    copy:
      "Os pés como base de estrutura do corpo, são uma das principais estruturas de suporte do nosso corpo e como tal estão sujeitas a várias lesões. A podologia trata as seguintes patologias: verrugas, micoses, calosidades, úlceras, joanetes, unhas encravadas/micóticas/grossas, alterações ortostáticas do pé em adultos e crianças (pé cavo/ pé plano).",
  },
  {
    id: "viscosuplementacao",
    title: "Viscosuplementação",
    subtitle: "Aplicação de solução de ácido hialurónico",
    copy:
      "Consiste na aplicação intra-articular de uma solução viscosa com ácido hialurónico exógeno, permite uma lubrificação natural da articulação, melhorando o deslizamento entre as superfícies articulares e consequentemente diminuindo a fricção entre estas estruturas. Indicado em alguns tipos de entorse e em alguns síndromes de fricção.",
  },
  {
    id: "epi",
    title: "EPI",
    subtitle: "Eletrólise Percutânea Intratecidual",
    copy:
      "A EPI ® - Eletrólise Percutânea Intratecidular é uma técnica minimamente invasiva que consiste na aplicação ecoguiada de uma corrente galvânica através de uma agulha de acupuntura que estimula o processo fagocitário. Permite desnaturar e eliminar unicamente o tecido degenerado, restabelecendo de forma natural a regeneração do tendão e a função dos tecidos moles. A EPI ® aplica-se no tratamento das seguintes lesões: lesão muscular (roturas/fibroses), lesão ligamentar, tendinopatia do Aquiles, tendinopatia rotuliana, tendinopatia da coifa dos rotadores, epicondilite, epitrocleíte, fasceíte plantar.",
  },
  {
    id: "ondasdechoque",
    title: "Ondas de choque",
    subtitle: "Aplicação de ondas acústicas de alta energia",
    copy:
      "A Terapia por Ondas de Choque consiste na aplicação de ondas acústicas de Alta Energia através de um aplicador sobre a pele. A passagem de energia pelos tecidos desencadeiam efeitos mecânicos que promovem uma ação regenerativa com a libertação de factores de crescimento, potenciando um aumento da vascularização local, bem como a estimulação da produção de colagénio e desagregação de calcificações. As ondas de Choque estão indicadas nas seguintes patologias: tendinites com ou sem calcificações, epicondilite, epitrocleíte, síndrome doloroso do trocânter (trocanterite), síndrome da banda Ilio-Tibial, tendinopatia Rótuliana, tendinopatia do Aquiles, tendinopatia da Pata de Ganso.",
  },
  {
    id: "treinofuncional",
    title: "Treino Funcional",
    subtitle: "Actividade física personalizada",
    copy:
      "Atividade física que procura integrar atividades quotidianas ou desportivas do individuo (a) para melhorar a sua condição física ou gestos técnicos. Pretende-se exercitar o corpo de uma forma integrada e não isolada. Grande foco no fortalecimento do core tendo por base movimentos naturais do ser humano como correr, saltar, agachar e empurrar. ",
  },
  {
    id: "medicinaregenerativa",
    title: "Medicina Regenerativa",
    subtitle: "Alterações funcionais",
    copy:
      "Especialidade médica que através de fatores de crescimento, viscosuplementação e toxina botulínica, juntamente com técnicas avançadas de fisioterapia, permite melhorar e restabelecer a integridade tecidular bem como a melhoria da sua função.",
  },
  {
    id: "psicologia",
    title: "Psicologia",
    subtitle: "Perturbações cognitivas e emocionais",
    copy:
      "Área clínica que avalia, diagnostica e intervém em perturbações cognitivas e emocionais através de planos de tratamento cientificamente validados de psicoterapia e apoio psicológico adequados a cada doente. A consulta de psicologia desenvolve-se através de uma colaboração entre paciente e psicólogo, com a finalidade de ajudar a lidar com as dificuldades e problemas para os quais o paciente sente não possuir os recursos necessários.",
  },
  {
    id: "nutricao",
    title: "Nutrição",
    subtitle: "Aconselhamento nutricional",
    copy:
      "Avaliação e aconselhamento nutricional personalizado para a manutenção de hábitos saudáveis que promovam o bem-estar e a saúde geral de qualquer individuo. A consulta de nutrição fornece um plano alimentar adequado às necessidades individuais prevenindo o aparecimento de inúmeras doenças.",
  },
  {
    id: "cirurgiavascular",
    title: "Cirurgia Vascular",
    subtitle: "Tratamento médico e cirúrgico",
    copy:
      "Diagnóstico clínico e imagiológico do sistema vascular promovendo o tratamento médico e cirúrgico das doenças que afetam vasos sanguíneos e linfáticos.",
  },
];
