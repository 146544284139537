import React from "react";
import { StaticQuery, graphql } from "gatsby";

const query = graphql`
  query {
    filipe: file(relativePath: { eq: "images/staff-filipe.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 2160, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    miguel: file(relativePath: { eq: "images/staff-miguel.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 2160, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    antonio: file(relativePath: { eq: "images/staff-antonio.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 2160, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    sofia: file(relativePath: { eq: "images/staff-sofia.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 2160, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    ana: file(relativePath: { eq: "images/staff-ana.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 2160, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    tiago: file(relativePath: { eq: "images/staff-tiago.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 2160, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    fernando: file(relativePath: { eq: "images/staff-fernando.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 2160, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Component => () => (
  <StaticQuery query={query} render={data => <Component data={data} />} />
);
