import React from "react";
import PropTypes from "prop-types";

import Section from "root/components/Section";
import BackgroundImage from "root/components/BackgroundImage";
import Header from "root/components/Header";
import Text from "root/components/Text";
import Button from "root/components/Button";
import Facebook from "root/assets/vectors/facebook.inline.svg";
import Instagram from "root/assets/vectors/instagram.inline.svg";
import Scroll from "root/assets/vectors/scroll.inline.svg";

import "./index.css";
import withQuery from "./withQuery";

function ServicesHero({ data }) {
  return (
    <>
      <BackgroundImage image={data.background.image}>
        <Section>
          <div styleName="root">
            <div styleName="content">
              <Header currentPage="/services" />

              <div styleName="middle">
                <Text variant="h1" fontFamily="open-sans-cond">
                  O que fazemos
                </Text>
                <Text styleName="copy">
                  Dispomos de tratamentos e tecnologia de ponta para ajudar na
                  reabilitação e prevenção das mais variadas patologias.
                </Text>
              </div>

              <div styleName="footer">
                <Scroll />

                <div styleName="follow-us desktop">
                  <Text variant="small">SEGUE-NOS</Text>
                  <a
                    href="https://www.facebook.com/Osteovida-531121787095765/"
                    aria-label="Instagram"
                  >
                    <Facebook />
                  </a>
                  <a
                    href="https://www.instagram.com/osteovida/"
                    aria-label="Instagram"
                  >
                    <Instagram />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Section>
      </BackgroundImage>

      <div styleName="mobile-footer">
        <div styleName="contact-us">
          <a href="#contactform">
            <Button>MARCA A TUA CONSULTA</Button>
          </a>
        </div>

        <div styleName="follow-us">
          <Text variant="small" color="grey" weight="bold">
            SEGUE-NOS
          </Text>

          <div styleName="social">
            <Facebook />
            <Instagram />
          </div>
        </div>
      </div>
    </>
  );
}

ServicesHero.propTypes = {
  data: PropTypes.shape({
    background: PropTypes.shape({
      image: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withQuery(ServicesHero);
