/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

import Gallery from "root/components/Gallery";
import Text from "root/components/Text";
import Section from "root/components/Section";
import PlusSign from "root/assets/vectors/plus.inline.svg";
import MinusSign from "root/assets/vectors/minus.inline.svg";
import Background from "root/components/Background";

import DOCTORS from "./data";
import withQuery from "./withQuery";

import "./index.css";

function renderDoctors(data) {
  return DOCTORS.map(doctor => {
    const [extended, setExtended] = useState(false);
    const handleClick = () => {
      setExtended(!extended);
    };
    const imageJsx = data[doctor.key] ? (
      <Img
        style={{ opacity: 0.9 }}
        styleName="image"
        objectFit="cover"
        fluid={data[doctor.key].image.fluid}
      />
    ) : null;

    return (
      <div styleName="background">
        <Background backgroundChildren={imageJsx}>
          <Section>
            <div styleName="copy" onClick={handleClick} role="presentation">
              {extended ? (
                <div styleName="doctor-extended">
                  <div styleName="doctor-overflow">
                    <Text
                      variant="h2"
                      weight="medium"
                      color="grey"
                      fontFamily="proxima-nova"
                      styleName="doctor-title"
                    >
                      {doctor.name}
                    </Text>

                    <Text color="light-grey" fontFamily="proxima-nova">
                      {doctor.position}
                    </Text>

                    <div styleName="doctor-extended-copy">{doctor.copy}</div>
                  </div>

                  <MinusSign />
                </div>
              ) : (
                <div styleName="doctor-min">
                  <Text
                    variant="h2"
                    weight="medium"
                    color="grey"
                    fontFamily="proxima-nova"
                  >
                    {doctor.name}
                  </Text>

                  <Text color="light-grey" fontFamily="proxima-nova">
                    {doctor.position}
                  </Text>

                  <PlusSign />
                </div>
              )}
            </div>
          </Section>
        </Background>
      </div>
    );
  });
}

function ServicesStaff({ data }) {
  return (
    <div styleName="root">
      <Gallery autoplay={false} darkArrows slides={renderDoctors(data)} />
    </div>
  );
}

ServicesStaff.propTypes = {
  data: PropTypes.object.isRequired,
};

export default withQuery(ServicesStaff);
