import React from "react";

import SEO from "root/components/SEO";
import Layout from "root/components/Layout";
import ServicesHero from "root/components/sections/ServicesHero";
import ServicesList from "root/components/sections/ServicesList";
import ServicesStaff from "root/components/sections/ServicesStaff";
import HomeContactForm from "root/components/sections/HomeContactForm";
import HomeContactInfo from "root/components/sections/HomeContactInfo";
import Footer from "root/components/Footer";

const IndexPage = () => (
  <Layout>
    <SEO title="Osteovida - Serviços" />
    <ServicesHero />
    <ServicesList />
    <ServicesStaff />
    <HomeContactForm />
    <HomeContactInfo />
    <Footer />
  </Layout>
);

export default IndexPage;
