/* eslint-disable global-require */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useRef, useLayoutEffect } from "react";
import classNames from "classnames";
import { findIndex } from "lodash";

import Section from "root/components/Section";
import Text from "root/components/Text";
import Arrow from "root/assets/vectors/arrow-short.inline.svg";
import Button from "root/components/Button";
import useLocation from "root/hooks/useLocation";

import QuestionsCopy from "./data";

import "./index.css";

function useServiceFromUrlHash() {
  if (typeof window === "undefined") return null;

  const { location } = useLocation();

  return findIndex(QuestionsCopy, question => {
    return question.id === location.hash.substr(1);
  });
}

export default function ServicesList() {
  const originalServiceIndex = useServiceFromUrlHash();

  const [selectedQuestion, setSelectedQuestion] = useState(
    originalServiceIndex,
  );

  const renderQuestion = (question, index) => {
    const questionRef = useRef(null);
    const showAnswer = selectedQuestion !== null && selectedQuestion === index;
    const toggleStyles = classNames("toggle", { close: showAnswer });

    useLayoutEffect(() => {
      if (!questionRef.current || !showAnswer) return;

      const y =
        questionRef.current.getBoundingClientRect().top + window.scrollY;

      window.scroll({
        top: y - 50,
        behavior: "smooth",
      });
    }, [showAnswer]);

    const handleClick = () =>
      showAnswer ? setSelectedQuestion(null) : setSelectedQuestion(index);
    const handleSpacebar = event => {
      if (event.key === " ") {
        event.preventDefault();
        handleClick();
      }
    };

    return (
      <div
        styleName="question-wrapper"
        ref={questionRef}
        onClick={handleClick}
        onKeyDown={handleSpacebar}
        tabIndex={0}
        role="button"
      >
        <div styleName="question">
          <Section>
            <div id={question.id} styleName="question-title">
              <div styleName="question-title-words">
                <Text variant="h2" color="grey" weight="medium">
                  {question.title}
                </Text>

                <Text color="light-grey">{question.subtitle}</Text>
              </div>

              <a href="#contactform" styleName="button">
                <Button>MARCA A TUA CONSULTA</Button>
              </a>

              <div styleName={toggleStyles} aria-hidden="true">
                <Arrow />
              </div>
            </div>

            <div styleName="answer-for-screenreaders">
              <Text>{question.copy}</Text>
            </div>

            {showAnswer ? (
              <div styleName="answer">
                <Text color="light-grey">
                  {QuestionsCopy[selectedQuestion].copy}
                </Text>

                <a styleName="button-mobile" href="#contactform">
                  <Button>MARCA A TUA CONSULTA</Button>
                </a>
              </div>
            ) : null}
          </Section>
        </div>
      </div>
    );
  };

  return (
    <div>
      {QuestionsCopy.map((question, index) => renderQuestion(question, index))}
    </div>
  );
}
