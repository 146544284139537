import React from "react";
import Text from "root/components/Text";

export default [
  {
    key: "filipe",
    name: "Dr. Filipe Alves",
    position: "Director Clínico / Fisioterapeuta",
    copy: (
      <>
        <Text color="light-grey" fontFamily="proxima-nova">
          Reabilitação das variadas patologias que afetam a componente
          músculo-esquelética.
        </Text>
        <br />
        <Text color="light-grey" fontFamily="proxima-nova">
          Com o apoio de tecnologias avançadas e técnicas minimamente invasivas
          é possível a regeneração muscular, tendinosa e ligamentosa de forma
          rápida e eficiente
        </Text>
        <br />
      </>
    ),
  },
  {
    key: "empty1",
    position: "Consulta de Dor Crónica e Medicina Regenerativa.",
    copy: (
      <>
        <Text color="light-grey" fontFamily="proxima-nova">
          Encontre respostas médicas inovadoras, sem consumo de fármacos e sem
          necessidade de cirurgia.
        </Text>
        <br />
        <Text color="light-grey" fontFamily="proxima-nova">
          Técnicas minimamente invasivas e estratégias de regeneração tecidular
          com recurso a procedimentos guiados por imagem, com a máxima segurança
          e melhor eficiência.
        </Text>
        <br />
      </>
    ),
  },
  {
    key: "antonio",
    name: "António Martins",
    position: "Personal Trainer/Treino Funcional",
    copy: (
      <>
        <Text color="light-grey" fontFamily="proxima-nova">
          Procuro avaliar e adaptar o treino funcional a cada pessoa permitindo
          que desenvolvam força, equilíbrio e flexibilidade ao mesmo tempo que
          atingem os objetivos desejados!
        </Text>
      </>
    ),
  },
  {
    key: "sofia",
    name: "Sofia Martins",
    position: "Assistente de Fisioterapia",
    copy: (
      <>
        <Text color="light-grey" fontFamily="proxima-nova">
          O acompanhamento dos nossos clientes desde da primeira sessão até ao
          fim dos tratamentos é crucial para assegurar que todas as suas
          necessidades e dúvidas sejam prontamente atendidas.
        </Text>
      </>
    ),
  },
  {
    key: "ana",
    name: "Dra. Ana Rita Teles",
    position: "Médica, Medicina Anti-Aging e Reposição Hormonal",
    copy: (
      <>
        <Text color="light-grey" fontFamily="proxima-nova">
          Conhecer o funcionamento do organismo, perceber a fisiopatologia do
          envelhecimento, permite-me atuar de forma personalizada no sentido da
          melhoria da qualidade de vida.
        </Text>
      </>
    ),
  },
  {
    key: "tiago",
    name: "Tiago Monteiro",
    position: "Osteopata Pediátrico",
    copy: (
      <>
        <Text color="light-grey" fontFamily="proxima-nova">
          Através de técnicas suaves e indolores é possível harmonizar o bebé/
          criança, melhorando o funcionamento de todo o seu organismo. Uma boa
          avaliação e abordagem é crucial para o equilíbrio funcional e
          estrutural da criança.
        </Text>
      </>
    ),
  },
  {
    key: "fernando",
    name: "Fernando Barros",
    position: "Acupunctor/Especialista em Medicina Tradicional Chinesa",
    copy: (
      <>
        <Text color="light-grey" fontFamily="proxima-nova">
          Aliado a um bom exame físico as técnicas de acupuntura permitem
          identificar os pontos do corpo a serem trabalhados para resolver as
          mais diversas patologias, procurando o equilíbrio do organismo em sua
          totalidade.
        </Text>
      </>
    ),
  },
];
